import React from "react";
import styled from "styled-components";
import testimonial1 from "../../assets/testimonial1.png";
import testimonial2 from "../../assets/testimonial2.png";
import avatar from "../../assets/avatar.png";

export default function Testimonials() {
  return (
    <Section id="testimonials">
      <div className="title">
        <h1>What Our Happy Customers Say About Us</h1>
      </div>
      <div className="testimonials">
        <div className="testimonial-image-one">
          <img src={testimonial1} alt="Testimonial" />
        </div>
        <div className="testimonial">
          <div className="title">
            <div className="image">
              <img src={avatar} alt="Avatar" />
            </div>
            <div className="info">
              <h3>Mohammad Seikh</h3>
              <span>Full Stack Developer</span>
            </div>
          </div>
          <p className="description">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </p>
        </div>
        <div className="testimonial-image-two">
          <img src={testimonial2} alt="Testimonial" />
        </div>
      </div>
    </Section>
  );
}
const Section = styled.section`
  .title {
    display: flex;
    justify-content: center;
    h1 {
      align-items: center;
      font-size: 2rem;
      width: 30%;
    }
  }
  .testimonials {
    display: flex;
    gap: 2rem;
    .testimonial {
      margin-top: 4rem;
      padding: 4rem 3rem;
      height: max-content;
      display: flex;
      flex-direction: column;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      gap: 2rem;
      border-top: 0.5rem solid var(--primary-color);
      .title {
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        .image {
          img {
            height: 3rem;
          }
        }
        .info {
          span {
            color: var(--primary-color);
          }
        }
      }
      .description {
        font-size: 1.1rem;
        line-height: 1.5rem;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin-top: 5rem;
    .title {
      display: flex;
      justify-content: center;
      h1 {
        text-align: center;
        font-size: 1.5rem;
        width: 80%;
      }
    }
    .testimonials {
      flex-direction: column;
      overflow: hidden;
      .testimonial-image-one,
      .testimonial-image-two {
        max-inline-size: 100%;
        block-size: auto;
      }
    }
  }
`;
