import React from "react";
import Navbar from "./Components/Navbar/Navbar";
import Destination from "./Components/Pages/Destination";
import DOwnloadApp from "./Components/Pages/DOwnloadApp";
import Footer from "./Components/Pages/Footer";
import Home from "./Components/Pages/Home";
import Offer from "./Components/Pages/Offer";
import ScrollToTop from "./Components/Pages/ScrollToTop";
import Services from "./Components/Pages/Services";
import Testimonials from "./Components/Pages/Testimonials";
import Tours from "./Components/Pages/Tours";

export default function App() {
  return (
    <div>
      <ScrollToTop />
      <Navbar />
      <Home />
      <Services />
      <Destination />
      <Offer />
      <Tours />
      <Testimonials />
      <DOwnloadApp />
      <Footer />
    </div>
  );
}
